import React from "react"
import Ipad from "../components/Ipad.js"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { graphql } from "gatsby"

const Question = ({ data }) => {
  const { slug, question, subtitle, answers, image } =
    data.allPagesJson.nodes[0]
  return (
    <Ipad data={{ bgColor: "var(--blu)" }}>
      <Container>
        <Topbar>
          <div></div>
          <Link to="/">
            <StaticImage
              height={50}
              layout="fixed"
              src="../images/x.png"
              alt="cross logo"
            />
          </Link>
        </Topbar>
        <div className="centered">
          {image === "cono" ? (
            <StaticImage
              layout="fixed"
              height={150}
              src="../images/cono.png"
              alt="cono"
            />
          ) : (
            <StaticImage
              layout="fixed"
              height={150}
              src="../images/cilindro.png"
              alt="cilindro"
            />
          )}

          {question && <h1>{question}</h1>}
          {subtitle && <p>{subtitle}</p>}
          {answers && (
            <div className="buttons">
              {answers.map((answer, i) => {
                if (answer.loading) {
                  return (
                    <Link
                      key={i}
                      to="/loading"
                      state={{ page: answer.to, source: slug }}
                    >
                      <button>{answer.text}</button>
                    </Link>
                  )
                } else {
                  return (
                    <Link key={i} to={answer.to}>
                      <button>{answer.text}</button>
                    </Link>
                  )
                }
              })}
            </div>
          )}
        </div>
      </Container>
    </Ipad>
  )
}

export const query = graphql`
  query GetQuestion($slug: String) {
    allPagesJson(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        question
        subtitle
        image
        answers {
          to
          text
          loading
        }
      }
    }
  }
`

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    h1 {
      font-family: GEB;
      color: white;
      text-transform: uppercase;
      font-size: 2.5rem;
      margin: 3rem 0;
      max-width: 600px;
      text-align: center;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        border: 1px solid var(--azul);
        background: transparent;
        color: white;
        font-family: GEB;
        padding: 1rem 2rem;
        border-radius: 4rem;
        font-size: 1.3rem;
        margin-bottom: 1.2rem;
        width: 300px;
        height: 5rem;
      }
    }
  }
`

const Topbar = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100px;
  margin-top: 2vw;
`

export default Question
